<template>
    <div class="menu-content">
        <main-nav-header></main-nav-header>
        <div class="content">
            <div class="row row-cols-3 mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <pie-chart :chart-data="pieData" :options="pieOptions"></pie-chart>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <horizontal-bar-chart :chart-data="horizontalBarData" :options="horizontalBarOptions"></horizontal-bar-chart>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <div>
                            <div class="d-flex align-items-center mb-3" v-for="(name,index) in users" :key="index">
                                <avatar size="sm" type="image" src="https://i.pravatar.cc/150?img=47"></avatar>
                                <div class="ml-3">
                                    <div class="font-14 mb-n2">{{ name }}</div>
                                    <span class="font-10 text-secondary">42hrs Completed | 42hrs Inprogress</span>
                                </div>
                                <div class="font-12 text-secondary ml-auto">
                                    {{ ordinal(index+1) }}
                                </div>
                            </div>
                        </div>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-1 mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <div class="row row-cols-2 align-items-center">
                            <div class="col">
                                <div class="row row-cols-3">
                                    <div class="col position-relative" v-for="(data,index) in triplePieChartData" :key="index">
                                        <pie-chart  :chart-data="data" :options="triplePieOptions"></pie-chart>
                                        <div class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center"
                                             style="top:0;left:0">
                                            <div class="text-center">
                                                <div class="font-26 font-weight-semibold">{{ data.number }}</div>
                                                <div class="text-secondary">{{ data.title }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col position-relative">
                                <bar-chart :chart-data="barChartData" :options="barChartOptions"></bar-chart>
                            </div>
                        </div>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-3 mb-4">
                <div class="col">
                    <layout-box style="min-height: 200px">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-20 font-weight-semibold">Header Title</div>
                                <div class="font-14 text-secondary">Date</div>
                            </div>
                            <fa class="ml-auto" icon="calendar-alt" size="lg"></fa>
                        </div>

                        <div class="d-flex align-items-center mt-5">
                            <fa icon="arrow-circle-up" size="2x" style="color:#6ACC4E"></fa>
                            <div class="display-4 ml-2">R 120k</div>
                        </div>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box style="min-height: 200px">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-20 font-weight-semibold">Header Title</div>
                                <div class="font-14 text-secondary">Date</div>
                            </div>
                            <fa class="ml-auto" icon="calendar-alt" size="lg"></fa>
                        </div>

                        <div class="d-flex align-items-center mt-5">
                            <fa icon="arrow-circle-down" size="2x" style="color:#D9534F"></fa>
                            <div class="display-4 ml-2">R 89k</div>
                        </div>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box style="min-height: 200px">
                        <div class="d-flex align-items-center">
                            <div>
                                <div class="font-20 font-weight-semibold">Header Title</div>
                                <div class="font-14 text-secondary">Date</div>
                            </div>
                            <fa class="ml-auto" icon="calendar-alt" size="lg"></fa>
                        </div>

                        <div class="d-flex align-items-center mt-5">
                            <fa icon="arrow-circle-up" size="2x" style="color:#6ACC4E"></fa>
                            <div class="display-4 ml-2">R 2.1M</div>
                        </div>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-2 mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <line-chart :chart-data="lineChartData" :options="lineChartOptions"></line-chart>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <bar-chart :chart-data="barChartData2" :options="barChartOptions2"></bar-chart>
                    </layout-box>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import MainNavHeader from "../../../src/components/MainNavHeader/MainNavHeader";
    import LayoutBox from "../../../src/components/LayoutBox/LayoutBox";
    import PieChart from "../../../src/components/Charts/PieChart";
    import HorizontalBarChart from "../../../src/components/Charts/HorizontalBarChart";
    import BarChart from "../../../src/components/Charts/BarChart";
    import Avatar from "../../../src/components/Avatar/Avatar";
    import LineChart from "../../../src/components/Charts/LineChart";

    export default {
        name: "Dashboard",
        components: {
            MainNavHeader,
            LayoutBox,
            PieChart,
            HorizontalBarChart,
            BarChart,
            LineChart,
            Avatar
        },
        data() {
            return {
                users:[
                    'Damien Gerlach', 'Joanny Cronin Sr.', 'Arvid Cormier', 'Monte Veum III', 'Jarrett Schneider', 'Malachi Ullrich', 'Ms. Toni Jast'
                ],
                pieData: {
                    labels: ['72hrs text', '72hrs text', '72hrs text'],
                    datasets: [
                        {
                            label: '72h',
                            backgroundColor: ['#F9BC0D', '#79D2DE', '#8EBB13'],
                            data: [72,72,72]
                        },
                    ]
                },
                pieOptions: {
                    title:{
                        position: 'bottom',
                        display: true,
                        text: 'Section Title'
                    },
                    legend: {
                        align: 'start',
                        position: 'bottom',
                        labels: {
                            boxWidth: 20
                        }
                    },
                    layout: {
                        padding: {
                            left: 80,
                            right:80,
                            top: 20,
                            bottom: 20
                        }
                    }
                },

                horizontalBarData: {
                    labels: ['UK', 'EU', 'US', 'CA', 'AU', 'SN', 'AF', 'NU'],
                    datasets: [{
                        backgroundColor: '#8EBB13',
                        categoryPercentage: 1,
                        barPercentage: 0.5,
                        minBarLength: 5,
                        maxBarThickness: 24,
                        data: [800, 950, 300, 200, 1400, 400, 1200, 100]
                    }],

                },

                horizontalBarOptions: {
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                min: 0,
                                max: 2000
                            }
                        }]
                    }
                },

                triplePieChartData: [
                    {
                        title: 'Applicants',
                        number: '1234',
                        datasets: [
                            {
                                backgroundColor: ['#707C86'],
                                data: [1,2]
                            },
                        ]
                    },
                    {
                        title: 'Inprogress',
                        number: '4326',
                        datasets: [
                            {
                                backgroundColor: ['#6BCC4F'],
                                data: [1,1]
                            },
                        ]
                    },
                    {
                        title: 'Completed',
                        number: '8949',
                        datasets: [
                            {
                                backgroundColor: ['#5CDDE6'],
                                data: [2,1]
                            },
                        ]
                    },
                ],
                triplePieOptions: {
                    cutoutPercentage: 70,
                },
                barChartData: {
                    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    datasets: [
                        {
                            categoryPercentage: 0.8,
                            barPercentage: .9,
                            barThickness: 'flex',
                            label: "Green",
                            backgroundColor: "#6ACC4E",
                            data: [4,3,7,6,6,5,4]
                        },
                        {
                            categoryPercentage: 0.8,
                            barPercentage: .9,
                            barThickness: 'flex',
                            label: "Blue",
                            backgroundColor: "#5BDDE6",
                            data: [6.5,6,1,5,3,6,5]
                        }
                    ],
                },
                barChartOptions: {
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: false
                    },
                    barValueSpacing: 30,
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 8
                            },
                            gridLines: {
                                display: false,
                            },
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }]
                    }
                },
                lineChartData: {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [{
                        backgroundColor: 'rgb(163,224,232,0.6)',
                        pointBackgroundColor: '#79D2DE',
                        borderColor: '#ACE3EB',
                        borderWidth: 2,
                        data: [20, 40, 80, 30, 120, 115, 136, 55, 9, 2, 38, 75]
                    }],
                },
                lineChartOptions: {
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 160,
                                padding: 20
                            },
                            gridLines: {
                                drawBorder: false,
                            },
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }]
                    }
                },

                barChartData2: {
                    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    datasets: [
                        {
                            categoryPercentage: 0.8,
                            barPercentage: .9,
                            barThickness: 40 ,
                            label: "Green",
                            backgroundColor: "#F9BC0D",
                            data: [6,4,5,7,8,2,1]
                        },
                    ],
                },
                barChartOptions2: {
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: false
                    },
                    barValueSpacing: 30,
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 8,
                                padding: 20
                            },
                            gridLines: {
                                drawBorder: false,
                            },
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }]
                    }
                },
            }
        },
        mounted() {
            this.$emit('toggleMenu','main', false);
        },
        methods: {
            ordinal(i) {
                let j = i % 10,
                    k = i % 100;
                if (j === 1 && k !== 11) {
                    return i + "st";
                }
                if (j === 2 && k !== 12) {
                    return i + "nd";
                }
                if (j === 3 && k !== 13) {
                    return i + "rd";
                }
                return i + "th";
            }
        }
    }
</script>
